'use client';

import { FC, useContext } from 'react';

import { List } from '@mui/material';

import { useSubscription } from 'common/hooks/useSubscription';
import { EditorTranslations } from 'common/pages/EditorPage/EditorTranslations.interface';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

import { MenuItem } from './components/MenuItem';

interface Props {
  readonly t: EditorTranslations;
}

export const MenuList: FC<Props> = ({ t }) => {
  const { trim, upload } = t;

  const editorModel = useContext(EditorModelContext);
  const videoId = useSubscription(editorModel.$videoId);

  return (
    <List>
      <MenuItem label={upload} tool="upload" />
      <MenuItem isDisabled={!videoId} label={trim} tool="trim" />
    </List>
  );
};
