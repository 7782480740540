'use client';

import { FC, useState } from 'react';

import TheatersIcon from '@mui/icons-material/Theaters';
import { Avatar, Button, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import { UploadedFile, uploadVideoFile } from 'common/api/uploadVideoFile.api';
import { LoadingErrorDoneStatus } from 'common/components/LoadingErrorDoneStatus/LoadingErrorDoneStatus';
import { useAuth } from 'common/hooks/useAuth';
import { VideoFile } from 'common/pages/EditorPage/models/types';

interface Props {
  videoFile: VideoFile;
}

export const UploadFileItem: FC<Props> = ({ videoFile }) => {
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [result, setResult] = useState<UploadedFile | null>(null);

  const handleStartClick = async () => {
    if (!token) {
      return;
    }
    try {
      setIsLoading(true);
      setResult(await uploadVideoFile(videoFile, token));
    } catch (e) {
      console.log('e', e);
      setError(e as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUseClick = async () => {
    // TODO
    // push to /editor/:id
  };

  return (
    <ListItem
      secondaryAction={
        <LoadingErrorDoneStatus
          defaultComponent={<Button onClick={handleStartClick}>Start uploading</Button>}
          doneComponent={<Button onClick={handleUseClick}>Use in Editor</Button>}
          isDone={!!result}
          isError={!!error}
          isLoading={isLoading}
        />
      }
    >
      <ListItemAvatar>
        <Avatar>
          <TheatersIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={videoFile.file.name} />
    </ListItem>
  );
};
