import * as React from 'react';
import { FC, useContext } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useSubscription } from 'common/hooks/useSubscription';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

export const TrimSettings: FC = () => {
  const editorModel = useContext(EditorModelContext);
  const videoId = useSubscription(editorModel.$videoId);

  return (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Typography sx={{ mt: 4 }} variant="h3">
        Trim
      </Typography>
      <Typography sx={{ mt: 4 }} variant="body2">
        {videoId}
      </Typography>
    </Box>
  );
};
