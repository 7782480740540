'use client';

import { FC, PropsWithChildren, useCallback, useContext, useEffect } from 'react';

import { getVideoData } from 'common/api/getVideoData.api';
import { useAuth } from 'common/hooks/useAuth';
import { useSubscription } from 'common/hooks/useSubscription';
import { COLOR } from 'common/styles/colors';

import { EditorModelContext } from '../../models/EditorModel';

export const EmptyBox: FC<PropsWithChildren> = ({ children }) => {
  console.log('EmptyBox');
  const { token } = useAuth();
  const editorModel = useContext(EditorModelContext);
  const videoId = useSubscription(editorModel.$videoId);
  const videoData = useSubscription(editorModel.$videoData);

  const loadingVideoData = useCallback(async () => {
    console.log('loadingVideoData');
    try {
      if (!videoId || !token) {
        return;
      }
      editorModel.$videoData.set(true);
      const data = await getVideoData(videoId, token);
      editorModel.$videoData.set(data);
    } catch (error) {
      console.log('error', error);
      editorModel.$videoData.set(error as Error);
    }
  }, [editorModel.$videoData, token, videoId]);

  useEffect(() => {
    if (!videoId) {
      if (videoData) {
        // clear previous video data
        editorModel.$videoData.set(undefined);
      }

      return;
    }

    if (videoData) {
      return;
    }

    void loadingVideoData();
  }, [videoId, videoData, editorModel.$videoData, loadingVideoData]);

  const handleClick = () => {
    editorModel.setTool('upload');
  };

  return (
    <>
      <div
        style={{
          alignItems: 'center',
          backgroundColor: COLOR.WHITE,
          borderRadius: 10,
          boxShadow: `0 8px 20px ${COLOR.BORDER}`,
          color: COLOR.BRAND,
          cursor: 'pointer',
          display: 'flex',
          fontFamily: 'Arial, sans-serif',
          fontSize: 16,
          height: 480,
          justifyContent: 'center',
          overflow: 'hidden',
          position: 'relative',
          width: 600
        }}
        onClick={handleClick}
      >
        <span style={{ color: '#fff', position: 'relative', zIndex: 1 }}>{children}</span>
        <div
          style={{
            animation: 'rotate 10s linear infinite',
            backgroundImage: `conic-gradient(${COLOR.PRIMARY_COLOR}99, ${COLOR.SECONDARY_COLOR}99, ${COLOR.PRIMARY_COLOR}99)`,
            height: '200%',
            left: '-50%',
            position: 'absolute',
            top: '-50%',
            width: '200%'
          }}
        />
        <div
          style={{
            animation: 'rotate 10s linear infinite',
            backgroundImage: `conic-gradient(${COLOR.PRIMARY_COLOR}99, ${COLOR.SECONDARY_COLOR}99, ${COLOR.PRIMARY_COLOR}99)`,
            filter: 'blur(20px)',
            height: '200%',
            left: '-50%',
            opacity: 0.6,
            position: 'absolute',
            top: '-50%',
            width: '200%'
          }}
        />
      </div>

      <style>{`
        @keyframes rotate {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
      `}</style>
    </>
  );
};
