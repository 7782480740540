import { blobToBase64 } from 'common/lib/blobToBase64';

import { getVideoIdFileUrl } from './uploadVideoFile.api';

export const getVideoData = async (videoId: string, jwtToken: string) => {
  const { link } = await getVideoIdFileUrl(videoId, jwtToken);
  const response = await fetch(link, {
    method: 'GET'
  });
  if (response.status > 400) {
    throw new Error('Failed to get video data');
  }

  const blob = await response.blob();

  const base64 = await blobToBase64(blob);

  return `data:video/mp4;base64,${base64}`;
};
