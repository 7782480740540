import { FC, useEffect, useState } from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import { ListItemButton, ListItemText } from '@mui/material';

import { getVideo } from 'common/api/getVideo.api';
import { GradientCircularProgress } from 'common/components/GradientCircularProgress/GradientCircularProgress';
import { useAuth } from 'common/hooks/useAuth';
import { useLangPrefix } from 'common/pages/EditorPage/hooks/useLangPrefix';

interface Props {
  videoId: string;
}

export const VideoItem: FC<Props> = ({ videoId }) => {
  const { token } = useAuth();
  const { prefix } = useLangPrefix();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    (async () => {
      if (!token) {
        return;
      }
      try {
        setIsLoading(true);
        await getVideo(videoId, token);
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [token, videoId]);

  return (
    <ListItemButton component="a" href={`${prefix}/editor/${videoId}`}>
      <ListItemText primary={videoId} />
      {isLoading && <GradientCircularProgress />}
      {error && <ErrorIcon />}
    </ListItemButton>
  );
};
