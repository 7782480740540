'use client';

import * as React from 'react';
import { FC, useContext } from 'react';

import { useSubscription } from 'common/hooks/useSubscription';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

import { ToolsSettingsBox } from '../EditorLayout/components';

import { TrimSettings } from './TrimSettings/TrimSettings';
import { UploadFiles } from './UploadFiles/UploadFiles';

export const ToolsSettings: FC = () => {
  const editorModel = useContext(EditorModelContext);
  const activeTool = useSubscription(editorModel.$activeTool);

  switch (activeTool) {
    case 'upload':
      return (
        <ToolsSettingsBox>
          <UploadFiles />
        </ToolsSettingsBox>
      );
    case 'trim':
      return (
        <ToolsSettingsBox>
          <TrimSettings />
        </ToolsSettingsBox>
      );
    default:
      return null;
  }
};
