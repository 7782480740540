import { useContext } from 'react';

import { useSubscription } from 'common/hooks/useSubscription';

import { EditorModelContext } from '../models/EditorModel';

export const useLangPrefix = () => {
  const editorModel = useContext(EditorModelContext);
  const lang = useSubscription(editorModel.$lang);
  const prefix = lang === 'en' ? '' : `/${lang}`;

  return { prefix };
};
