'use client';

import * as React from 'react';
import { FC, MouseEvent, useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { GlobalTranslations } from 'common/i18n/GlobalTranslations.interface';
import { useLocale } from 'common/i18n/useLocale';
import { Tools, ToolsVideoCompressor, ToolsVideoConverter, ToolsVideoTrimmer } from 'common/routes';

interface Props {
  gt: GlobalTranslations;
}

export const MobileMenu: FC<Props> = ({ gt }) => {
  const { Navigation } = gt;
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { locale } = useLocale();

  const toggleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(v => (v ? null : event.currentTarget));
  };

  const handleClose = () => {
    setAnchorElNav(null);
  };

  const open = !!anchorElNav;

  return (
    <Box>
      <IconButton
        aria-controls={open ? 'main-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        color="inherit"
        id="main-menu-button"
        size="large"
        onClick={toggleOpenNavMenu}
      >
        <MenuIcon />
      </IconButton>
      <Box sx={{ position: 'absolute' }}>
        <Menu
          anchorEl={anchorElNav}
          id="main-menu"
          MenuListProps={{
            'aria-labelledby': 'main-menu-button'
          }}
          open={open}
        >
          <MenuItem onClick={handleClose}>
            <Typography textAlign="center">
              <Tools.Link lang={locale} style={{ color: 'inherit', textDecoration: 'none' }}>
                {Navigation.Tools}
              </Tools.Link>
            </Typography>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <Typography textAlign="center">
              <ToolsVideoTrimmer.Link
                lang={locale}
                style={{
                  color: 'inherit',
                  textDecoration: 'none'
                }}
              >
                {Navigation.VideoTrimmer}
              </ToolsVideoTrimmer.Link>
            </Typography>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <Typography textAlign="center">
              <ToolsVideoCompressor.Link
                lang={locale}
                style={{
                  color: 'inherit',
                  textDecoration: 'none'
                }}
              >
                {Navigation.VideoCompressor}
              </ToolsVideoCompressor.Link>
            </Typography>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <Typography textAlign="center">
              <ToolsVideoConverter.Link
                lang={locale}
                style={{
                  color: 'inherit',
                  textDecoration: 'none'
                }}
              >
                {Navigation.VideoConverter}
              </ToolsVideoConverter.Link>
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};
