'use client';

import { FC, PropsWithChildren, useContext } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Divider, IconButton, Toolbar, useTheme } from '@mui/material';

import { useSubscription } from 'common/hooks/useSubscription';
import { EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';

import { EditorTranslations } from '../../EditorTranslations.interface';

import { Drawer, DrawerHeader } from './components';
import { MenuList } from './MenuList/MenuList';

interface Props {
  readonly t: EditorTranslations;
}

export const ToolsMenu: FC<PropsWithChildren & Props> = ({ children, t }) => {
  const theme = useTheme();
  const editorModel = useContext(EditorModelContext);
  const open = useSubscription(editorModel.$isToolsMenuOpened);

  const handleDrawerToggle = () => {
    editorModel.toggleToolsMenu();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer open={open} variant="permanent">
        <Toolbar />
        <DrawerHeader>
          <IconButton onClick={handleDrawerToggle}>
            {theme.direction === 'rtl' || open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box sx={{ overflow: 'auto' }}>
          <MenuList t={t} />
        </Box>
      </Drawer>
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
    </Box>
  );
};
