'use client';

import { Box, Typography } from '@mui/material';
import { usePathname } from 'next/navigation';

import { PageContainer } from 'common/components/PageContainer/PageContainer';
import { Locale } from 'common/i18n/i18n.config';
import { I18nNavigation } from 'common/i18n/types';
import { ToolsVideoCompressor, ToolsVideoConverter, ToolsVideoCrop, ToolsVideoTrimmer } from 'common/routes';

import { Item } from './Item';

interface Props {
  Navigation: I18nNavigation;
  label: string;
  lang: Locale;
}

export const HeroDiscoverTools = ({ label, lang, Navigation }: Props) => {
  const pathname = usePathname();

  if (!label) {
    return null;
  }

  return (
    <Box>
      <Typography sx={{ mb: 4, textAlign: 'center' }} variant="h2">
        {label}
      </Typography>
      <PageContainer sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', pb: 10, pt: 5 }}>
        <Item
          image="/images/icons/video-trimmer-icon_90x90.svg"
          isActive={ToolsVideoTrimmer.isActive(lang, pathname)}
          label={Navigation.VideoTrimmer}
          lang={lang}
          Link={ToolsVideoTrimmer.Link}
        />
        <Item
          image="/images/icons/video-compressor-icon_90x90.svg"
          isActive={ToolsVideoCompressor.isActive(lang, pathname)}
          label={Navigation.VideoCompressor}
          lang={lang}
          Link={ToolsVideoCompressor.Link}
        />
        <Item
          image="/images/icons/video-converter-icon_90x90.svg"
          isActive={ToolsVideoConverter.isActive(lang, pathname)}
          label={Navigation.VideoConverter}
          lang={lang}
          Link={ToolsVideoConverter.Link}
        />
        {Navigation.VideoCrop && (
          <Item
            image="/images/icons/crop-video-96x96.svg"
            isActive={ToolsVideoCrop.isActive(lang, pathname)}
            label={Navigation.VideoCrop}
            lang={lang}
            Link={ToolsVideoCrop.Link}
          />
        )}
      </PageContainer>
    </Box>
  );
};
