'use client';

import * as React from 'react';
import { FC, useContext } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { LogoSvg } from 'common/components/Icons/LogoSvg';
import { GlobalTranslations } from 'common/i18n/GlobalTranslations.interface';
import { Locale } from 'common/i18n/i18n.config';
import { BORDER } from 'common/styles/colors';

import { useSubscription } from '../../hooks/useSubscription';
import { EditorModelContext } from '../../pages/EditorPage/models/EditorModel';

import { AuthMenu } from './components/AuthMenu';

interface Props {
  readonly gt: GlobalTranslations;
  readonly lang: Locale;
}

export const TopEditorBar: FC<Props> = ({ gt, lang }) => {
  const { Videmix } = gt;

  const editorModel = useContext(EditorModelContext);
  const videoId = useSubscription(editorModel.$videoId);

  return (
    <AppBar color="inherit" sx={{ borderBottom: BORDER, boxShadow: 'none', zIndex: 10000 }}>
      <Container component="nav" maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            component={Link}
            href="/"
            lang={lang}
            sx={{
              color: 'inherit',
              display: { md: 'flex', xs: 'none' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              mr: 2,
              textDecoration: 'none',
              textTransform: 'uppercase'
            }}
            title={Videmix}
            variant="h6"
            noWrap
          >
            <LogoSvg />
          </Typography>

          <Typography
            component={Link}
            href="/"
            lang={lang}
            sx={{
              color: 'inherit',
              display: { md: 'none', xs: 'flex' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              mr: 2,
              textDecoration: 'none',
              textTransform: 'uppercase'
            }}
            title={Videmix}
            variant="h5"
            noWrap
          >
            <LogoSvg />
          </Typography>

          <Box sx={{ display: { md: 'flex', xs: 'none' }, flexGrow: 1 }}>{videoId}</Box>

          <Box sx={{ display: 'flex', flexGrow: 0 }}>
            <AuthMenu gt={gt} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
