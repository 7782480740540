import { VIDEOS_URL } from '../const/api.const';

export const getVideo = async (videoId: string, jwtToken: string) => {
  const response = await fetch(`${VIDEOS_URL}/${videoId}`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    },
    method: 'GET'
  });

  const data = await response.json();

  if (response.status > 400) {
    throw new Error(data.message);
  }

  return data;
};
