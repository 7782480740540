'use client';

import { useEffect, useState } from 'react';

import { Atom } from '@grammarly/focal';
import { Observable } from 'rxjs';

export const useSubscription = <T>(model: Atom<T> | Observable<T>) => {
  const [state, setState] = useState<T | undefined>('get' in model ? model.get() : undefined);

  useEffect(() => {
    const subscription = model.subscribe(setState);

    return () => subscription.unsubscribe();
  }, [model]);

  return state;
};
