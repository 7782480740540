import * as React from 'react';
import { FC, useState } from 'react';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { COLOR } from 'common/styles/colors';

import { OpenFileModal } from './OpenFileModal/OpenFileModal';
import { UploadingFilesModal } from './UploadingFilesModal/UploadingFilesModal';
import { useUploadFiles } from './useUploadFiles';

export const UploadFiles: FC = () => {
  const { getInputProps, getRootProps, handleCloseUploadModal, isDragActive, videoFiles } = useUploadFiles();

  const [openFileModal, setOpenFileModal] = useState(false);

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', m: 4, textAlign: 'center' }}>
      <Button sx={{ mb: 3 }} variant="contained" fullWidth onClick={() => setOpenFileModal(true)}>
        Open Uploaded Video
      </Button>
      <OpenFileModal open={openFileModal} onClose={() => setOpenFileModal(false)} />
      {!!videoFiles.length && <UploadingFilesModal videoFiles={videoFiles} onClose={handleCloseUploadModal} />}
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', textAlign: 'center' }} {...getRootProps()}>
        <input {...getInputProps()} />

        <Button sx={{ mb: 3 }} variant="contained" fullWidth>
          Upload new file
        </Button>
        <CardActionArea
          sx={{
            background: '#fff',
            borderColor: isDragActive ? 'red' : 'rgba(33, 150, 243, 0.5)',
            borderStyle: 'dashed',
            borderWidth: 1,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Box>
            <UploadFileIcon sx={{ color: COLOR.PRIMARY_COLOR }} />
          </Box>
          <Typography sx={{ color: COLOR.PRIMARY_COLOR }} variant="body2">
            Upload or drop a video
          </Typography>
        </CardActionArea>
      </Box>
    </Box>
  );
};
