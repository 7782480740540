import { FC, useCallback, useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List } from '@mui/material';

import { getVideos } from 'common/api/getVideos.api';
import { LoadingErrorDoneStatus } from 'common/components/LoadingErrorDoneStatus/LoadingErrorDoneStatus';
import { useAuth } from 'common/hooks/useAuth';

import { VideoItem } from './VideoItem';

interface Props {
  onClose: () => void;
  open: boolean;
}

export const OpenFileModal: FC<Props> = ({ onClose, open }) => {
  const { token } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const [videosIds, setVideosIds] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      if (!token) {
        return;
      }
      try {
        setIsLoading(true);
        setVideosIds(await getVideos(token));
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [token]);

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      maxWidth="sm"
      open={open}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">Open video</DialogTitle>
      <DialogContent sx={{ maxHeight: 400 }}>
        <LoadingErrorDoneStatus
          doneComponent={
            <List>
              {videosIds.map(videoId => (
                <VideoItem key={videoId} videoId={videoId} />
              ))}
            </List>
          }
          isDone={!isLoading}
          isError={!!error}
          isLoading={isLoading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
