import { VIDEOS_URL } from '../const/api.const';

export const getVideos = async (jwtToken: string) => {
  const response = await fetch(VIDEOS_URL, {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    },
    method: 'GET'
  });
  const { ids } = (await response.json()) as { ids: string[] };

  return ids;
};
