import { Atom } from '@grammarly/focal';

import { Nullable } from 'common/lib/types/assert';

export interface PlayerState {
  readonly contentElement: Nullable<HTMLDivElement>;
  readonly isPaused: Nullable<boolean>;
  readonly videoElement: Nullable<HTMLVideoElement>;
}

export class PlayerModel {
  public readonly contentElement: Atom<Nullable<HTMLDivElement>>;
  public readonly videoElement: Atom<Nullable<HTMLVideoElement>>;
  public readonly isPaused: Atom<Nullable<boolean>>;

  constructor(public readonly state: Atom<PlayerState>) {
    this.contentElement = this.state.lens('contentElement');
    this.videoElement = this.state.lens('videoElement');
    this.isPaused = this.state.lens('isPaused');
  }

  setContentElement(contentElement: Nullable<HTMLDivElement>) {
    this.state.modify(state => ({ ...state, contentElement }));
  }

  setVideoElement(videoElement: Nullable<HTMLVideoElement>) {
    this.state.modify(state => ({ ...state, videoElement }));
  }

  play() {
    this.state.modify(state => ({ ...state, isPaused: false }));
  }

  pause() {
    this.state.modify(state => ({ ...state, isPaused: true }));
  }
}

const DEFAULT_PLAYER_STATE: PlayerState = {
  contentElement: null,
  isPaused: null,
  videoElement: null
};

export const createPlayerModel = (state: Partial<PlayerState> = {}) =>
  new PlayerModel(Atom.create({ ...DEFAULT_PLAYER_STATE, ...state }));
