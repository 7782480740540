'use client';

import * as React from 'react';
import { FC } from 'react';

import { TopEditorBar } from 'common/components/TopAppBar/TopEditorBar';
import { GlobalTranslations } from 'common/i18n/GlobalTranslations.interface';
import { Undefined } from 'common/lib/types/assert';
import { createEditorModel, EditorModelContext } from 'common/pages/EditorPage/models/EditorModel';
import { PageProps } from 'common/pages/PageProps';

import { EditorTranslations } from '../EditorTranslations.interface';

import { Canvas } from './Canvas/Canvas';
import { EditorLayout } from './EditorLayout/EditorLayout';
import { Timeline } from './Timeline/Timeline';
import { ToolsSettings } from './ToolsSettings/ToolsSettings';

interface Props extends PageProps {
  readonly gt: GlobalTranslations;
  readonly t: EditorTranslations;
  readonly videoId: Undefined<string>;
}

export const Editor: FC<Props> = ({ gt, lang, t, videoId }) => (
  <EditorModelContext.Provider value={createEditorModel({ lang, t, videoId })}>
    <EditorLayout
      header={<TopEditorBar gt={gt} lang={lang} />}
      t={t}
      timeline={<Timeline />}
      toolsSettings={<ToolsSettings />}
    >
      <Canvas />
    </EditorLayout>
  </EditorModelContext.Provider>
);
