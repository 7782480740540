import { VIDEO_FILE_UPLOAD_URL, VIDEO_ID_DOWNLOAD_URL } from 'common/const/api.const';

import { VideoFile } from '../pages/EditorPage/models/types';

export interface UploadedFile {
  link: string;
  videoId: string;
}

const uploadFileToAws = async (link: string, videoFile: VideoFile) =>
  await fetch(link, {
    body: videoFile.file,
    method: 'PUT'
  });

export const uploadVideoFile = async (videoFile: VideoFile, jwtToken: string): Promise<UploadedFile> => {
  const response = await fetch(VIDEO_FILE_UPLOAD_URL, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
      'X-File-Name': videoFile.file.name,
      'X-Media-Type': videoFile.file.type
    },
    method: 'POST'
  });

  const { link, videoId } = (await response.json()) as UploadedFile;

  console.log('response.data', { link, videoId });

  await uploadFileToAws(link, videoFile);

  // TODO: Wait for the file to be indexed

  return { link, videoId };
};

export const getVideoIdFileUrl = async (videoId: string, jwtToken: string): Promise<UploadedFile> => {
  const response = await fetch(`${VIDEO_ID_DOWNLOAD_URL}${videoId}`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    },
    method: 'GET'
  });

  return response.json();
};
